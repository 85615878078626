<template>
    <div class="content full table-view table-management">
        <!-- <v-app id="vuetify"> -->

        <div v-if="filter_visible && !condensedView" class="filter-column hide-scrollbar">
            <label>{{ $t('vtf.search') }}</label>
            <input class="input-search" type="text" v-model="search" />

            <div v-for="(item, index) in current_filters" class="filter" :data-type="item.type">
                <label>{{ item.label }}</label>

                <div class="value">
                    <v-select v-model="item.current_value" v-if="item.type == 2" :items="item.options" :label="item.label"></v-select>

                    <v-switch v-model="item.current_value" v-if="item.type == 3" :label="item.label"></v-switch>

                    <div v-if="item.type == 6">
                        <v-range-slider v-model="item.current_value" color="#475b96" thumb-color="#475b96" thumb-size="40" thumb-label="always" @change="updateFilters()"></v-range-slider>
                    </div>

                    <div class v-if="item.type == 'dateRange'">
                        <button class="btn date-range" @click="filterDateRange(item.current_value)"></button>

                        <span v-if="item.current_value.length <= 0">{{ $t('nvgt.filters.range.unset') }}</span>
                        <span v-if="item.current_value.length > 0">{{ item.current_value[0] }} - {{ item.current_value[1] }}</span>
                    </div>

                    <v-select v-if="item.type == 'multiple'" v-model="item.current_value" :items="getOptions(item.options, item)" @change="updateFilters()" multiple chips small-chips>
                        <template v-slot:selection="{ data, index }">
                            <v-chip v-if="index === 0">
                                <span>{{ data.text }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="caption">(+{{ itm.current_value.length - 1 }} seleccionado)</span>
                        </template>
                    </v-select>

                    <div v-if="item.type == 'tags'">
                        <span @click="selectStatus('all')" class="tag all">{{ $t('nvgt.select.action_all') }}</span>
                        <span v-for="tag in item.options" class="tag" :class="{ selected: item.current_value.indexOf(tag.value) > -1 }" @click="selectStatus(tag.value)">{{ tag.text }}</span>
                    </div>
                </div>
            </div>

            <button @click="resetFilters" class="btn clear-filter">{{ $t('nvgt.filters.clear_filter') }}</button>
        </div>

        <div class="data-column" :class="{ large: !filter_visible || condensedView, condensed: condensedView }">
            <div v-if="!condensedView" class="filter-bar">
                <div class="bttns">
                    <button @click="filterColumns()" class="btn view-column"></button>
                    <button @click="filter_visible = !filter_visible" class="btn open-filter" :class="{ opened: filter_visible }"></button>
                </div>

                <div class="filters">
                    <div v-for="(itm, index) in main_filters" class="filter" :data-type="itm.type">
                        <!-- <div class="label">{{ itm.label }}</div> -->
                        <div class="value">
                            <v-select v-if="itm.type == 'multiple'" v-model="itm.current_value" :items="getOptions(itm.options, itm)" @change="updateFilters()" multiple chips :label="itm.label" :placeholder="$t('nvgt.select.all')">
                                <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="index === 0">
                                        <span>{{ item.text }}</span>
                                    </v-chip>
                                    <span v-if="index === 1" class="caption">(+{{ itm.current_value.length - 1 }} {{ $tc('nvgt.select.selected', itm.current_value.length - 1) }})</span>
                                </template>
                            </v-select>

                            <v-select v-if="itm.type == 'select'" v-model="itm.current_value" :items="getOptions(itm.options, itm)" @change="updateFilters()" :label="itm.label"></v-select>

                            <v-select v-if="itm.type == 'selectDate'" v-model="itm.current_value" :items="getOptions(itm.options, itm)" @change="updateFilters()">
                                <template v-slot:selection="{ item, index }">
                                    <v-list-tile-content>
                                        <v-list-tile-title v-html="item.text"></v-list-tile-title>
                                        <v-list-tile-sub-title>{{ momentInitEnd(itm.current_value[0], itm.current_value[1]) }}</v-list-tile-sub-title>
                                    </v-list-tile-content>
                                </template>
                                <template v-slot:item="{ item, index }">
                                    <v-list-tile-content v-if="!item.action">
                                        <v-list-tile-title>{{ item.text }}</v-list-tile-title>
                                    </v-list-tile-content>
                                    <span v-if="item.action" class="btn-select" @click="filterDateRange(item.value)">{{ item.text }}</span>
                                </template>
                            </v-select>
                        </div>
                    </div>
                </div>

                <button v-if="canAdd" class="btn create" @click="doCreate">{{ $t('nvgt.' + page + '.create') }}</button>
                <!-- <button v-if="selected.length > 0" class="btn view-detail" @click="viewDetail">{{ $t('nvgt.view_detail') }}</button> -->
            </div>

            <div v-if="condensedView" class="filter-bar back" @click="viewDetail">
                <span class="text">{{ $t('assets.column.go_back') }}</span>
            </div>

            <v-data-table class="datatable-container hide-scrollbar" :headers="headers" :items="values" :search="search" :options="pagination" :no-data-text="$t('vtf.datatable.no-data')" :no-results-text="$t('vtf.datatable.no-result')" v-model="selected" show-select :footer-props.items-per-page-options="[10, 25, 50, { text: $t('vtf.datatable.all-rows'), value: -1 }]" :footer-props.items-per-page-option="''" ref="datatable">
                <template v-slot:headers="props">
                    <tr>
                        <th v-if="condensedView" class="column">{{ $t('nvgt.' + page + '.condensed.title') }}</th>

                        <th v-if="!condensedView" class="column col-icon" width="40px">
                            <!-- checkbox seleccion multiple -->
                            <!-- <v-checkbox :input-value="props.all" :indeterminate="props.indeterminate" primary hide-details @click.stop="toggleAll"></v-checkbox> -->
                        </th>
                        <th v-if="!condensedView" role="columnheader" v-for="header in props.headers" :key="header.text" :width="header.width" :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '', header.fixed ? 'fixed-column' : '']" v-show="header.visible" @click="changeSort(header.value)">
                            <v-icon small>arrow_upward</v-icon>
                            {{ header.text }}
                        </th>
                    </tr>
                </template>

                <template v-slot:items="props">
                    <tr v-if="!condensedView" :active="props.selected" @click="toggleSelected(props)">
                        <td class="col-icon" width="30px">
                            <!-- <v-checkbox :input-value="props.selected" primary hide-details :key="props.item.id"></v-checkbox> -->
                        </td>
                        <td v-for="(column, index) in headers" v-show="column.visible" :data-type="column.type" :data-value="props.item[column.value]" class="column" :class primary v-html="rowFormat(props.item[column.value], column)"></td>
                    </tr>

                    <tr v-if="condensedView" :active="props.selected" @click="toggleSelected(props)">
                        <td class="column">
                            <span v-for="(row, index) in headers" v-if="row.condensed" :data-type="row.type" :class="row.condensed" v-html="rowFormat(props.item[row.value], row)"></span>
                        </td>
                    </tr>
                </template>

                <template v-slot:pageText="props">{{ $t('vtf.datatable.result') }} {{ props.itemsLength }}</template>
                <template v-slot:no-results>{{ $t('vtf.datatable.no-result') }}</template>
            </v-data-table>
        </div>

        <div class="preview" v-if="condensedView">
            <div class="preview-bar">
                <div class="title-bar">
                    <span v-show="!onAddedit">{{ $t('nvgt.title_detail') }}</span>
                    <span v-show="onAddedit">{{ condensedOpts.adding ? $t('nvgt.' + page + '.create') : $t('nvgt.' + page + '.editing') }}</span>
                </div>

                <button v-if="canAdd" v-show="!onAddedit" class="btn create" @click="doCreate">{{ $t('nvgt.' + page + '.create') }}</button>
                <button v-if="canEdit" v-show="selected.length > 0 && !onAddedit" class="btn edit" @click="condensedOpts.editing = true">{{ $t('nvgt.edit') }}</button>

                <button v-show="onAddedit" class="btn save" @click="condensedOpts.doSave = true">{{ $t('nvgt.save') }}</button>
                <button v-show="onAddedit" class="btn cancel" @click="cancelChanges">{{ $t('nvgt.cancel') }}</button>
            </div>

            <div v-if="condensedView && (selected.length > 0 || onAddedit)" class="preview-container" :class="{ adding: condensedOpts.adding }">
                <component :is="componentView" :gValue="componentValue" :gData="{}" :gOptions="condensedOpts" @changeOpts="changeOpts"></component>
            </div>
            <div v-else class="preview-container empty">{{ $t('nvgt.' + page + '.empty') }}</div>
        </div>

        <!-- </v-app> -->
    </div>
</template>

<script>
import ManageEmployee from '@/components/domain/manage/employee/detail'
import ManageProduct from '@/components/domain/manage/products/detail'

export default {
    name: 'Table',
    components: {
        ManageEmployee,
        ManageProduct
    },
    props: { schema: Array, data: Object, page: String },
    data() {
        return {
            condensedView: false,
            selected: [],
            pagination: {
                sortBy: 'name',
                rowsPerPage: 25
            },
            search: '',
            condensedOpts: {
                showing: false,
                editing: false,
                adding: false,
                doSave: false
            },
            hidden_columns: this.$store.getters['navigation/getCurrentPage'].hidden_columns ? this.$store.getters['navigation/getCurrentPage'].hidden_columns : [],
            filter_visible: false,
            filters: this.$store.getters['navigation/getCurrentPage'].filters ? JSON.parse(JSON.stringify(this.$store.getters['navigation/getCurrentPage'].filters)) : false,
            selects: this.$store.getters['navigation/getCurrentPage'].selects ? JSON.parse(JSON.stringify(this.$store.getters['navigation/getCurrentPage'].selects)) : false,
            actions: this.$store.getters['navigation/getCurrentPage'].actions ? JSON.parse(JSON.stringify(this.$store.getters['navigation/getCurrentPage'].actions)) : false,
            navInfo: this.$store.getters['navigation/getCurrentPage'] ? JSON.parse(JSON.stringify(this.$store.getters['navigation/getCurrentPage'])) : false
        }
    },
    computed: {
        headers: {
            get: function () {
                var headers = []
                var width = 0
                var currentWidth = typeof document.getElementById('fullcontent') !== 'undefined' ? document.getElementById('fullcontent').offsetWidth : 0

                // console.log("this.schema");
                // console.log(this.schema);
                this.schema.forEach((raw) => {
                    //console.log(this.hidden_columns.indexOf(raw.id));
                    if (raw.type != 8 && raw.type != 9) {
                        var column = {
                            text: raw.label,
                            value: raw.param,
                            sortable: typeof raw.sortable !== 'undefined' ? raw.sortable : true,
                            condensed: typeof raw.condensed !== 'undefined' ? raw.condensed : false,
                            type: raw.type,
                            format: raw.format,
                            options: raw.options,
                            visible: this.hidden_columns.indexOf(raw.param) != -1 ? false : true,
                            blocked: false,
                            width: typeof raw.width !== 'undefined' ? raw.width : '',
                            fixed: typeof raw.fixed !== 'undefined' ? raw.fixed : false
                        }
                        headers.push(column)

                        if (column.width != '') {
                            if (column.width.indexOf('%') != -1) {
                                width += currentWidth / (100 / parseInt(column.width.replace('%', '')))
                            } else {
                                width += parseInt(column.width.replace('px', ''))
                            }
                            // console.log( width );
                            // width += ( ( width != "" )? " + " : "" ) + column.width;
                        }
                    }
                })

                // console.log( width );
                if (width != 0) {
                    width += (currentWidth / headers.length) * headers.length
                    // console.log( width );
                    setTimeout(function () {
                        // console.log( document.getElementsByClassName( 'v-table' )[0] );
                        if (typeof document.getElementsByClassName('v-table')[0] !== 'undefined') {
                            document.getElementsByClassName('v-table')[0].style.width = width + 'px'
                        }
                    }, 200)
                }

                // console.log( headers );

                return headers
            },
            set: function (value) {
                //console.log("SET HEADERS " + value)
                this.headers = value
            }
        },
        values() {
            var values = []
            for (var i in this.data) {
                var row = this.data[i]
                values.push(row)
            }
            // console.log("values");
            // console.log(values);
            return values
        },
        main_filters() {
            return this.selects
        },
        current_filters() {
            // console.log( "CURRENT_FILTERS" );
            return this.filters
        },
        allFilters() {
            return [...this.current_filters, ...this.main_filters]
        },

        onAddedit() {
            if (this.condensedOpts.adding || this.condensedOpts.editing) {
                return true
            } else {
                return false
            }
        },

        canAdd() {
            if (typeof this.actions['add'] != 'undefined') {
                return true
            } else {
                return false
            }
        },
        canEdit() {
            if (typeof this.actions['edit'] != 'undefined') {
                return true
            } else {
                return false
            }
        },

        componentView() {
            return this.page
        },
        componentValue() {
            if (this.condensedOpts.adding) {
                // return {};
            } else {
                if (this.selected.length) {
                    return this.selected[0]
                } else {
                    // console.log("else");
                    // return {};
                }
            }
        }
    },
    methods: {
        toggleAll() {
            if (this.selected.length) this.selected = []
            else {
                this.selected = this.$refs.datatable.filteredItems.slice()
                // this.selected = this.values.slice();
            }
            // console.log("filteredItems - "+this.$refs.datatable.filteredItems.length);
            // console.log(this.$refs.datatable.filteredItems);
            // console.log("selected - "+ this.selected.length);
            // console.log(this.selected);
        },
        toggleSelected(row) {
            if (this.selected.length) {
                if (row.item.id != this.selected[0].id) {
                    this.selected.splice(0, this.selected.length)
                    row.selected = !row.selected
                } else {
                    this.selected.splice(0, this.selected.length)
                }
            } else {
                row.selected = !row.selected
            }

            if (this.actions['detail'] != 'undefined') {
                if (this.actions['detail'].click != 'undefined' && this.actions['detail'].click == 'row') {
                    this.cancelChanges()
                    if (this.actions['detail'].condensed != 'undefined' && this.actions['detail'].condensed) {
                        if (!this.condensedView) {
                            this.viewDetail()
                        }
                    }
                }
            }
        },

        updateFilters() {
            this.$emit('applyFilters', this.allFilters)
        },
        filterColumns() {
            var self = this
            this.$filter({
                list: this.headers,
                selected: self.hidden_columns,
                left: this.filter_visible ? '20%' : 0,
                callback: function (d) {
                    self.hidden_columns = [...d]
                    self.saveFilter(self.hidden_columns)
                }
            })
            this.$filter.show()
        },

        saveFilter(filter) {
            this.$store.commit('navigation/setHiddenColumns', filter)
            // if(localStorage) {
            // 	localStorage.col_register = JSON.stringify(filter);
            // }
        },

        changeSort(column) {
            if (this.pagination.sortBy === column) {
                this.pagination.descending = !this.pagination.descending
            } else {
                this.pagination.sortBy = column
                this.pagination.descending = false
            }
        },

        rowFormat(value, column) {
            // console.log("-----");
            // console.log(column);
            // console.log(value);
            // console.log(value && typeof value != 'undefined');
            // console.log("-----");

            if (value && typeof value != 'undefined') {
                switch (column.type + '') {
                    case '1':
                    case '2':
                    case '3':
                    case '4':
                    case '6':
                    case '10':
                        return value

                    case 'dateRange':
                        return value

                    case '7':
                        return moment(value).format('DD MMM YYYY')

                    case '12':
                        return moment(value).format('HH:mm')

                    case '13':
                        return moment(value).format('DD MMM YYYY HH:mm')

                    case 'date':
                        return moment(value).format(column.format ? column.format : 'DD MMM YYYY')

                    case '15':
                        return value + 'ºC'

                    case '%':
                        return value + '%'

                    case 'multiple':
                        for (var i in column.options) {
                            var opt = column.options[i]
                            if (opt.value == value) {
                                return opt.text
                            }
                        }

                    case 'object':
                        var result = ''
                        for (var i in value) {
                            var obj = value[i]
                            result += "<span class='tag'>" + obj.name + '</span>'
                        }
                        return result

                    case 'states':
                        var result = ''
                        for (var i in value) {
                            var obj = value[i]
                            if (obj.time > 0) {
                                result += '<p>' + obj.name + ' (' + this.formatTime(obj.time) + ')</p>'
                            } else if (obj.time == 0 && obj.msg != '') {
                                result += '<p>' + obj.name + ' ( mensaje )</p>'
                            } else {
                                result += '<p>' + obj.name + '</p>'
                            }
                            if (obj.next) {
                                result += "<p class='next-state'>" + obj.next_name + '</p>'
                                // result += "<p class='next-state'>"+ obj.next_name +"("+this.formatTime(obj.next_time)+")</p>";
                            }
                        }
                        return result

                    case 'employee':
                        return "<span class='avatar' style='background-image: url(" + value.avatar + ")'></span>" + value.name

                    case 'avatar':
                        return "<span class='miniature' style='background-image: url(" + value + ")'></span>"

                    case 'status':
                        return value

                    default:
                        return value
                }
            } else {
                return '-'
            }
        },

        applyFilters() {
            this.$emit('applyFilters', this.filters)
        },
        resetFilters() {
            var filters = [...this.copy_filters]
            if (filters) {
                filters.forEach((filter) => {
                    // console.log( filter.current_value );
                    switch (filter.type) {
                        case 2:
                            filter.current_value = -1
                            break
                        case 3:
                            filter.current_value = 0
                            break
                    }
                })
            }

            this.copy_filters = filters

            // console.log( this.copy_filters );
        },
        getOptions(options, filter) {
            if (typeof options === 'string') {
                var aOptions = []
                if (typeof filter.optionsValues !== 'undefined') {
                    var values = this.$store.getters[options](filter.optionsValues)
                } else {
                    var values = this.$store.getters[options]
                }
                for (var i in values) {
                    var value = values[i]
                    aOptions.push({ value: value.id, text: value.name })
                }
                // console.log(options);
                // console.log(values);
                return aOptions
            } else {
                return options
            }
        },

        filterDateRange(range) {
            var self = this
            range = range.length > 0 ? range : [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]

            this.$popup.dateRange({
                title: self.$t('nvgt.filters.range.title'),
                dateStart: null,
                dateEnd: null,
                dateRange: range,
                textSave: self.$t('nvgt.filters.range.text_save'),
                textCancel: self.$t('nvgt.filters.range.text_cancel'),
                callSave: function (pSelf, store) {
                    for (var i in self.current_filters) {
                        var filter = self.current_filters[i]

                        if (filter.param == 'date_range') {
                            filter.current_value = pSelf.dateRange
                        }
                    }
                    self.updateFilters()
                    self.$popup.close()
                }
            })
        },

        selectStatus(status) {
            for (var i in this.current_filters) {
                var filter = this.current_filters[i]

                if (filter.param == 'status') {
                    if (status != 'all') {
                        var index = filter.current_value.indexOf(status)
                        if (index > -1) {
                            filter.current_value.splice(index, 1)
                        } else {
                            filter.current_value.push(status)
                        }
                    } else {
                        filter.current_value = []
                    }
                }
            }
            this.updateFilters()
        },

        viewDetail() {
            this.condensedView = !this.condensedView
            this.condensedOpts.showing = false
            this.cancelChanges()
        },
        doCreate() {
            this.condensedView = true

            this.condensedOpts.adding = true
            this.condensedOpts.editing = true
        },

        cancelChanges() {
            this.condensedOpts.adding = false
            this.condensedOpts.editing = false
        },

        compare(val1, val2, operator = '=') {
            //console.log("COMPARE " + val1 + " " + operator + " " + val2)
            switch (operator) {
                case '=':
                    return val1 == val2
                case '>':
                    return parseFloat(val1) > parseFloat(val2)
                case '>=':
                    return parseFloat(val1) >= parseFloat(val2)
                case '<':
                    return parseFloat(val1) < parseFloat(val2)
                case '<=':
                    return parseFloat(val1) <= parseFloat(val2)
                case '<>':
                    if (typeof val2 === 'string') {
                        var values = val2.split(',')
                        return parseFloat(val1) >= parseFloat(values[0]) && parseFloat(val1) <= parseFloat(values[1])
                    }
                    return false
                case '!<>':
                    if (typeof val2 === 'string') {
                        var values = val2.split(',')
                        return !(parseFloat(val1) >= parseFloat(values[0]) && parseFloat(val1) <= parseFloat(values[1]))
                    }
                    return false
                default:
                    return false
            }
        },

        subtasks(answer, value) {
            if (typeof answer.config.conditions != 'undefined') {
                var result = []
                var conditions = answer.config.conditions

                for (var i in conditions) {
                    var subtask = conditions[i]
                    var operator = subtask.operator ? subtask.operator : '='
                    var val = subtask.value

                    var show = false

                    if (value !== null) {
                        if (operator == 'true' && val == 'true') {
                            show = true
                        } else {
                            switch (answer.type) {
                                case 1:
                                    show = this.compare(parseInt(val) == 1, value == true || value == 'true', operator)
                                    break
                                case 2:
                                    show = this.compare(parseInt(val) == 1, value == true || value == 'true', operator)
                                    break
                                case 3:
                                    show = this.compare(parseInt(val), value, operator)
                                    break
                                case 11:
                                    show = this.compare(val, value, operator)
                                    break
                                case 6:
                                case 15:
                                    show = this.compare(parseFloat(value), val, operator)
                                    break
                                default:
                                    break
                            }
                        }
                    }
                    if (show) {
                        result.push(subtask.item_id)
                    }
                }
                return result
            }
        },

        feedback(answer, value) {
            if (typeof answer.config != 'undefined' && typeof answer.config.alerts != 'undefined') {
                var message = ''
                var alerts = answer.config.alerts

                for (var i in alerts) {
                    var alert = alerts[i]
                    var operator = alert.operator ? alert.operator : '='
                    var val = alert.value

                    var show = false

                    if (value !== null) {
                        switch (answer.type) {
                            case 1:
                                show = this.compare(parseInt(val) == 1, value == true || value == 'true', operator)
                                break
                            case 2:
                                show = this.compare(parseInt(val) == 1, value == true || value == 'true', operator)
                                break
                            case 3:
                                show = this.compare(parseInt(val), value, operator)
                                break
                            case 11:
                                show = this.compare(val, value, operator)
                                break
                            case 6:
                            case 15:
                                show = this.compare(parseFloat(value), val, operator)
                                break
                            default:
                                break
                        }
                    }

                    if (show && alert.msg) {
                        if (message == '') message = alert.msg
                        else message = message + '<br/>' + alert.msg
                    }
                }

                if (message.length > 0) {
                    return message
                } else {
                    return false
                }
            }
        },

        sendPrint() {
            window.print()
        },

        momentInitEnd(start, end) {
            if (start == end) {
                return moment(start).format('DD MMMM')
            } else {
                return moment(start).format('DD MMMM') + this.$t('nvgt.select.dft_date.to') + moment(end).format('DD MMMM')
            }
        },

        changeOpts(newOpts) {
            this.condensedOpts = newOpts
        },

        timeLeft(deadtime) {
            var now = moment()
            var text = ''

            var years = deadtime.diff(now, 'year')
            now.add(years, 'years')

            var months = deadtime.diff(now, 'months')
            now.add(months, 'months')

            var days = deadtime.diff(now, 'days')
            now.add(days, 'days')

            var hours = deadtime.diff(now, 'hours')
            now.add(hours, 'hours')

            var minutes = deadtime.diff(now, 'minutes')
            now.add(minutes, 'minutes')

            var seconds = deadtime.diff(now, 'seconds')
            now.add(seconds, 'seconds')

            if (years > 0) {
                if (years == 1) {
                    text += years + ' ' + i18n.tc('checklist.timing.year', 1)
                } else if (years > 1) {
                    text += years + ' ' + i18n.tc('checklist.timing.year', 2)
                }
            } else if (years <= 0 && months > 0) {
                if (months == 1) {
                    text += months + ' ' + i18n.tc('checklist.timing.month', 1)
                } else if (months > 1) {
                    text += months + ' ' + i18n.tc('checklist.timing.month', 2)
                }

                if (text.length > 1 && days != 0) {
                    text += ' ' + i18n.t('checklist.timing.connector') + ' '
                }

                if (days == 1) {
                    text += days + ' ' + i18n.tc('checklist.timing.day', 1)
                } else if (days > 1) {
                    text += days + ' ' + i18n.tc('checklist.timing.day', 2)
                }
            } else if (years <= 0 && months <= 0 && days > 0) {
                if (days == 1) {
                    text += days + ' ' + i18n.tc('checklist.timing.day', 1)
                } else if (days > 1) {
                    text += days + ' ' + i18n.tc('checklist.timing.day', 2)
                }

                if (text.length > 1 && hours != 0) {
                    text += ' ' + i18n.t('checklist.timing.connector') + ' '
                }

                if (hours == 1) {
                    text += hours + ' ' + i18n.tc('checklist.timing.hour', 1)
                } else if (hours > 1) {
                    text += hours + ' ' + i18n.tc('checklist.timing.hour', 2)
                }
            } else if (years <= 0 && months <= 0 && days <= 0 && hours > 0) {
                if (hours == 1) {
                    text += hours + ' ' + i18n.tc('checklist.timing.hour', 1)
                } else if (hours > 1) {
                    text += hours + ' ' + i18n.tc('checklist.timing.hour', 2)
                }

                if (text.length > 1 && minutes != 0) {
                    text += ' ' + i18n.t('checklist.timing.connector') + ' '
                }

                if (minutes == 1) {
                    text += minutes + ' ' + i18n.tc('checklist.timing.minute', 1)
                } else if (minutes > 1) {
                    text += minutes + ' ' + i18n.tc('checklist.timing.minute', 2)
                }
            } else if (years <= 0 && months <= 0 && days <= 0 && hours <= 0 && minutes > 0) {
                if (minutes == 1) {
                    text += minutes + ' ' + i18n.tc('checklist.timing.minute', 1)
                } else if (minutes > 1) {
                    text += minutes + ' ' + i18n.tc('checklist.timing.minute', 2)
                }
            } else if (years <= 0 && months <= 0 && days <= 0 && hours <= 0 && minutes <= 0 && seconds > 0) {
                text += i18n.t('checklist.timing.inminent')
            }

            return i18n.t('checklist.timing.remind', { s: text })
        },
        formatTime(n) {
            var num = n
            var hours = num / 60
            var rhours = Math.floor(hours)
            var minutes = (hours - rhours) * 60
            var rminutes = Math.round(minutes)
            var text = ''
            if (rhours > 0) {
                text += rhours + 'h '
            }
            if (rminutes > 0) {
                text += rminutes + 'min '
            }
            if (typeof num == 'undefined') {
                text = 'text'
            }
            return text
        }
    },
    watch: {
        $route(to, from) {
            this.condensedView = false
            this.selected = []
            this.search = ''
            this.condensedOpts = {
                showing: false,
                note: true,
                desc: true
            }
            this.hidden_columns = this.$store.getters['navigation/getCurrentPage'].hidden_columns ? this.$store.getters['navigation/getCurrentPage'].hidden_columns : []
            this.filters = JSON.parse(JSON.stringify(this.$store.getters['navigation/getCurrentPage'].filters))
            this.selects = JSON.parse(JSON.stringify(this.$store.getters['navigation/getCurrentPage'].selects))
            this.actions = JSON.parse(JSON.stringify(this.$store.getters['navigation/getCurrentPage'].actions))
            this.navInfo = JSON.parse(JSON.stringify(this.$store.getters['navigation/getCurrentPage']))
        }
    }
}
</script>

<style lang="scss">
.table-view.table-management {
    $preview-bar-height: 50px;

    width: 100%;
    position: relative;

    .filter-column {
        float: left;
        width: 20%;
        min-width: 200px;
        padding: 12px 24px;
        border-right: 1px solid #ccc;
        position: fixed;
        height: 100%;
        overflow-y: auto;

        .filter {
            margin-top: 24px;

            // .v-menu__content {
            //     position: fixed !important;
            // }

            &[data-type='6'] {
                label {
                    margin-bottom: 50px;
                }
                .value {
                    height: 30px;
                }
            }

            &[data-type='tags'] {
                .tag {
                    @include border-radius(3px);
                    @include font-size(14px);
                    font-family: $text;
                    text-align: center;
                    display: inline-block;
                    // border: 1px solid $color-primary-500;
                    background-color: #f2f2f2;
                    margin: 4px;
                    padding: 6px 9px;
                    color: $color-primary-800;
                    cursor: pointer;
                    transition-duration: 0.2s;

                    &:hover {
                        background-color: #eee;
                    }

                    &.all {
                        background-color: $color-primary-800;
                        color: #fff;

                        &:hover {
                            background-color: $color-primary-500;
                        }
                    }
                    &.selected {
                        background-color: $color-primary-100;

                        &:hover {
                            background-color: $color-primary-100;
                        }
                    }
                }
            }
        }

        .input-search {
            @include background($size: 18px, $position: left 6px center, $image: img('search_aaaaaa.svg'));
            width: 100%;
            padding-left: 30px;
        }

        .btn.date-range {
            @include background($color: #eee, $size: 18px, $image: img('input_date_dark.svg'));
            width: 30px;
            height: 30px;
            margin-right: 6px;
        }

        .v-slider__thumb-label span {
            @include font-size(16px);
            color: #fff;
            font-family: $text;

            &:after {
                content: '%';
            }
        }

        .btn.clear-filter {
            @include font-size(16px);
            color: #777;
            display: block;
            margin: 36px auto 0 auto;
            background-color: #eee;
        }
    }

    .filter-bar {
        background-color: #fff;
        padding: 15px;
        padding-top: 24px;
        height: 80px;

        .bttns {
            display: inline-block;
            float: left;
            margin-right: 12px;

            .btn {
                @include opacity(0.8);
                @include background($color: #f5f5f5, $size: 18px);
                display: inline-block;
                margin: 0;
                margin-right: 9px;
                padding: 0;
                width: 35px;
                height: 35px;

                &.view-column {
                    background-image: img('view_column_dark.svg');
                }
                &.open-filter {
                    background-image: img('filter_dark.svg');
                    background-size: 16px;

                    &.opened {
                        @include opacity(1);
                        background-image: img('filter_primary.svg');
                    }
                }
            }
        }
        .filters {
            @include display-inline-flex();
            @include align-items();
            @include flex-wrap(wrap);
            width: auto;
            max-width: calc(100% - 100px - 150px);

            .filter {
                display: inline-block;
                margin-right: 25px;

                .v-label {
                    margin: 0;
                    height: unset;
                }
                .value {
                    display: inline-block;

                    .v-select {
                        padding: 0;
                        margin: 0;
                        height: 40px;
                    }
                }
            }

            .filter[data-type='selectDate'] {
                .v-list__tile__content {
                    overflow: visible;
                }
                .v-select__selections {
                    > input[readonly='readonly'] {
                        display: none;
                    }
                }

                .v-list__tile__title,
                .v-list__tile__sub-title {
                    @include text-ellipsis();
                    max-width: 190px;
                    height: 20px;
                }
                .v-list__tile__title {
                    @include font-size(15px);
                }
                .v-list__tile__sub-title {
                    @include font-size(14px);
                    text-transform: uppercase;
                    font-family: $text;
                }
            }

            // Por que eres así vuetify?
            .v-text-field > .v-input__control > .v-input__slot:before,
            .v-text-field > .v-input__control > .v-input__slot:after {
                bottom: 1px !important;
            }
            .v-select .v-input__slot {
                margin: 0 !important;
            }

            .v-select__selections {
                // @include text-ellipsis();

                width: 190px;
                // margin-top: 3px;
                min-height: 40px !important;
            }
            .v-select__selections .v-chip + input,
            .v-select__selections .caption + input {
                // width: 0px;
                // padding: 0;
                // margin: 0;
            }

            .v-select .v-input__slot .v-menu .v-menu__content {
                // top: 20px !important;
                // left: unset !important;
                // right: 0 !important;
                // max-width: 230px;
            }

            .v-chip .v-chip__content {
                height: 28px;
            }
            .v-chip .v-chip__content span {
                @include text-ellipsis();
                max-width: 100px;
            }
        }

        &.back {
            @include background($image: img('left_arrow_dark.svg'), $size: 20px, $position: left 12px center);
            padding: 0;
            width: 100%;
            height: 50px;
            text-align: right;
            cursor: pointer;

            .text {
                display: inline-block;
                height: 30px;
                line-height: 30px;
                width: calc(100% - 45px);
                color: #666;
                border-left: 1px solid #ddd;
                margin: 10px 0;
                padding-left: 12px;
            }
        }
    }

    .btn {
        @include background($size: 16px, $color: $color-primary-500, $position: right 6px center);
        @include font-size(16px);
        line-height: 1;
        color: $color-success-800;
        font-family: $text;
        float: right;
        max-width: 150px;
        width: auto;
        height: 30px;
        padding: 6px 10px;
        border-radius: 3px;
        margin-left: 6px;

        &.create,
        &.save {
            background-color: $color-success-100;
            color: $color-success-800;
        }
        &.cancel {
            background-color: $color-error-100;
            color: $color-error-500;
        }

        &.print {
            color: #222;
            display: inline-block;
        }

        &.print-opts {
            @include background($image: img('more_hor_dark.svg'), $size: 16px, $color: #eee);
            @include opacity(0.6);
            @include font-size(16px);
            line-height: 1;
            font-family: $text;
            color: #222;
            display: inline-block;
            width: 30px;
            height: 30px;
            margin-left: 0;

            &.more {
            }
            &.less {
                background-color: #ddd;
            }
        }

        &.settings {
            @include background($image: img('levels_dark.svg'), $size: 18px, $color: #eee, $position: center);
            @include opacity(0.6);
            @include font-size(16px);
            line-height: 1;
            font-family: $text;
            color: #222;
            display: inline-block;
            width: 30px;
            height: 30px;
            margin: 10px;
            float: left;

            &.less {
                background-color: #ddd;
            }
        }
    }

    .data-column {
        float: left;
        width: 80%;
        height: 100%;
        margin-left: 20%;

        &.large {
            width: 100%;
            margin-left: 0;

            .v-table {
                .v-datatable__actions {
                    width: 100%;
                    left: 0;
                }
            }
        }
        &.condensed {
            width: 20%;
            min-width: 200px;
            max-width: 20%;
            margin-left: 0;

            .datatable-container {
                height: calc(100% - 80px - 30px);
                table-layout: fixed;
                overflow: auto;
            }

            .v-table {
                width: inherit !important;

                .v-datatable__progress {
                    display: none;
                }

                // thead .column{
                //
                // }

                tbody .column {
                    position: relative;
                    width: 100%;
                    height: 50px;
                    min-height: 50px;
                    display: inline-block;
                    padding: 3px 0;

                    .name,
                    .data,
                    .subname,
                    .subdata {
                        @include text-ellipsis();
                        position: absolute;
                        height: 20px;
                        // display: block;
                    }

                    .name,
                    .subname {
                        left: 6px;
                    }
                    .data,
                    .subdata {
                        right: 6px;
                        text-align: right;
                    }
                    .name,
                    .data {
                        top: 6px;
                        // top: 0;
                    }
                    .subname,
                    .subdata {
                        @include text-ellipsis();
                        @include font-size(13px);
                        line-height: 1.5;
                        top: 26px;
                        left: 6px;
                        // bottom: 0;
                    }

                    .name {
                        width: 80%;
                        font-family: $text;
                    }
                    .data {
                        @include font-size(20px);
                        font-family: $text-bold;
                        width: 20%;
                    }
                    .subname {
                        &[data-type='object'] {
                            .tag {
                                margin-right: 2px;
                                &:after {
                                    content: ',';
                                }
                            }
                            .tag:last-of-type {
                                margin-right: 0;
                                &:last-of-type:after {
                                    content: '';
                                }
                            }
                        }
                    }
                    .subdata {
                        text-transform: uppercase;
                        color: #999;
                    }
                }
                .col-icon {
                    width: 50px;
                    height: auto;
                    display: inline-block;
                }

                .v-datatable__actions {
                    width: 20%;
                    min-width: 200px;
                    left: 0;
                }
            }
        }

        .datatable-container {
            height: calc(100% - 80px - 60px);
            table-layout: fixed;
            overflow: auto;
        }
        table.v-table {
            table-layout: fixed;
            max-width: 200% !important;
        }
        .v-table {
            thead {
                // background-color: $color-primary-100;

                // .column {
                //   @include font-size(14px);
                //   font-family: $text;
                //   color: $color-primary-500;
                //   // padding: 0;
                //   padding: 15px 12px;
                // }

                // .col-icon {
                //   padding: 15px 12px;
                //   overflow-x: hidden;
                // }
            }

            tbody {
                .col-icon {
                    padding: 15px 12px;
                }

                .column {
                    padding: 10px 12px;
                    // padding: 6px 0;
                    // text-align: center;

                    &[data-type='1'][data-value='0'],
                    &[data-type='status'][data-value='0'] {
                        @include background($image: img('error_alert.svg'), $size: 25px);
                    }
                    &[data-type='1'][data-value='1'],
                    &[data-type='status'][data-value='1'] {
                        @include background($image: img('checked_ok.svg'), $size: 25px);
                    }

                    &[data-type='status'] {
                        color: transparent;
                        background-position: 25px center !important;
                    }

                    &[data-type='states'],
                    &[data-type='object'] {
                        .tag {
                            @include font-size(13px);
                            margin-right: 3px;
                            &:after {
                                content: ',';
                            }
                        }
                        .tag:last-of-type {
                            margin-right: 0;
                            &:last-of-type:after {
                                content: '';
                            }
                        }

                        p {
                            @include font-size(13px);
                        }
                        p + .next-state {
                            padding-bottom: 0;
                        }
                        .next-state {
                            @include background($image: img('next-state_dark.svg'), $size: 18px, $position: left 9px center);
                            padding-top: 0;
                            padding-left: 32px;
                        }
                    }

                    &[data-type='%'] {
                        @include font-size(20px);
                        font-family: $text-bold;
                    }
                    &[data-type='avatar'] .miniature,
                    &[data-type='employee'] .avatar {
                        @include border-radius(50%);
                        @include background($size: contain);
                        display: block;
                        float: left;
                        width: 40px;
                        height: 40px;
                        margin-right: 6px;
                    }

                    img {
                        height: 50px;
                        width: auto;
                    }
                }
            }

            .v-datatable__actions {
                position: fixed;
                bottom: 0;
                left: 20%;
                width: 80%;
                height: 60px;

                .v-datatable__actions__select {
                    width: 60px;
                    margin-right: 12px;
                }

                .v-datatable__actions__pagination {
                    @include font-size(13px);
                    font-family: $text;
                    color: #999;
                    text-transform: uppercase;
                    margin: 0 6px;
                }

                // Este es el amigo select de nº de pags a mostrar xdxdxd
                .v-menu {
                    position: relative;
                }
                .v-menu__content {
                    // position: absolute;
                    // top: -190px !important;
                    // left: unset !important;
                    // right: 0 !important;
                    // z-index: 1;
                }
            }
        }
    }

    .preview {
        display: block;
        width: 80%;
        height: 100%;
        float: right;

        .preview-bar {
            height: $preview-bar-height;
            // background-color: #fff;
            text-align: right;
            position: relative;
            padding: 10px;

            > .title-bar {
                @include text-ellipsis();
                @include font-size(26px);
                font-family: $text;
                line-height: 1.8;
                color: $color-primary-800;
                position: absolute;
                z-index: 2; //TODO Cambiar este z-index por variables porfa (doc. _variables.scss)
                left: 12px;
                top: 0;
                width: auto;
                max-width: 400px;
                height: 100%;

                span {
                    font-size: inherit;
                    font-family: inherit;
                    line-height: inherit;
                    color: inherit;
                }
            }
        }

        // .set-print, .set-preview {
        //   @include border-radius(0 0 3px 3px);
        //   position: absolute;
        //   z-index: 2;
        //   left: 3px;
        //   top: 50px;
        //   width: auto;
        //   height: auto;
        //   padding: 6px;
        //   background-color: #f9f9f9;
        //   border: 1px solid #eee;
        //   border-top: none;
        //
        //   .title {
        //     @include font-size(16px);
        //     color: $color-primary-500;
        //     margin: 0;
        //     padding: 0;
        //     margin: 0 auto 6px auto;
        //   }
        //   .subtitle {
        //     @include font-size(13px);
        //     max-width: 250px;
        //     margin: 0 0 3px 0;
        //     padding: 0;
        //     color: #777;
        //   }
        //
        //   .btn {
        //     @include display-flex();
        //     @include justify-content();
        //     @include align-items();
        //     font-family: $text;
        //     color: $color-primary-500;
        //     background: none;
        //     margin: 6px;
        //     padding: 0;
        //     height: auto;
        //     width: auto;
        //
        //     .text {
        //       @include font-size(16px);
        //       color: $color-primary-500;
        //       font-family: $text;
        //       display: inline-block;
        //       margin: 0;
        //       padding: 0;
        //       margin-left: 6px;
        //     }
        //   }
        //
        //   .switch {
        //     @extend .switch-input;
        //   }
        // }
        .set-print {
            left: unset;
            right: 3px;
        }

        .preview-container {
            background-color: #eee;
            height: calc(100% - 50px);
            overflow: auto;

            &.empty {
                @include font-size(20px);
                @include background($image: img('empty4.svg'), $size: 150px, $position: top 50px center);
                padding-top: 230px;
            }

            &.adding {
                width: 100%;
                height: calc(100% - (#{$menu-top-height} + #{$preview-bar-height}));
                position: fixed;
                top: calc(#{$menu-top-height} + #{$preview-bar-height});
                left: 0;
            }
        }
    }

    .v-menu__content {
        // .v-card {
        //   box-shadow: none;
        // }
    }
    .v-list__tile .btn-select {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        text-align: left !important;
        display: block;
        line-height: 2.5;
    }
}
</style>
