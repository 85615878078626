<template>
  <div id="content" class="list-view">
    <!--<Topbar></Topbar>-->
    <Table :schema="schema" :data="data" :page="page" @applyFilters="new_filters"></Table>
  </div>
</template>

<script>

import Table from '@/components/ui/TableManagement'
import Topbar from '@/components/ui/TopbarManagement'

export default {
  name: 'ListViewManagement',
  components: { Table, Topbar },
  props: {
    page: { type: String },
    date: { type: String },
    action: { type: String },
    type: { type: Number, default: -1 },
  },
  data () {
    return {

    }
  },
  computed: {
    schema() {
      // console.log(this.$store.getters[ 'navigation/getCurrentPage' ].columns);
      return this.$store.getters[ 'navigation/getCurrentPage' ].columns ? this.$store.getters[ 'navigation/getCurrentPage' ].columns : [];
    },
    filters() {
      return this.$store.getters[ 'navigation/getCurrentPage' ].filters ? this.$store.getters[ 'navigation/getCurrentPage' ].filters : [];
    },
    selects() {
      return this.$store.getters[ 'navigation/getCurrentPage' ].selects ? this.$store.getters[ 'navigation/getCurrentPage' ].selects : [];
    },
    actions() {
      return this.$store.getters[ 'navigation/getCurrentPage' ].actions ? this.$store.getters[ 'navigation/getCurrentPage' ].actions : {};
    },
    navInfo() {
      return this.$store.getters[ 'navigation/getCurrentPage' ] ? this.$store.getters[ 'navigation/getCurrentPage' ] : {};
    },
    data(){
      if( this.navInfo.storeName == 'checklist' && this.type != -1 ){
        var list = this.$store.getters[ 'checklist/getList' ](this.type);
      } else {
        var list = this.$store.getters[ this.navInfo.storeName + '/getList' ];
      }

      var oList = {};
      for( var i in list ){
        var row = list[ i ];
        oList[ row.id ] = row;
      }
      // console.log("oList");
      // console.log(oList);
      return oList;
    }
  },
  created() {
    // console.log( " --- PAGE " + this.page + " --- " );
    this.load();
  },
  watch: {
    '$route': 'load'
  },
  methods: {
    new_filters( filters ){
      // console.log( " --- NEW FILTERS --- " );
      // console.log( filters );
      var self = this;
      var params = {};

      for( var i in filters ){
        params[filters[i].param] = filters[i].current_value;
      }
      self.$overlay.show();

      // console.log("PASA POR FILTERS");
      // console.log(params);

      self.$store.dispatch( self.navInfo.storeName + '/loadList', params ).then( function(){
        self.$overlay.hide();
      });

    },

    load(){
      var self = this;

      self.$overlay.loading();
      self.$store.commit( 'setPage', self.page );

      if( self.navInfo.storeName == 'checklist' && typeof self.navInfo.storeName != 'undefined' ){
        self.$store.dispatch( 'checklist/loadList', {'type': self.type, 'date': self.date } ).then( function(){
          self.$overlay.hide();
        });
      } else {
        self.$store.dispatch( self.navInfo.storeName + '/loadList', {} ).then( function(){
          self.$overlay.hide();
        });
      }

    },



  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">


</style>
