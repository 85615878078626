<template>
    <div class="group productstate">
        <div name="select" class="check" @click="addeleteSelected()">
            <div class="tick" v-if="selected"></div>
        </div>
        <select class="selectStates" v-model="activeState.id">
            <option v-if="!state.used || state.id == activeState.id" v-for="state in states" :key="state.id" v-bind:value="state.id" :selected="checkState('id', state) ? 'selected' : ''">{{ state.name }}</option>
        </select>
        {{ activeState }}
        <button class="input inputSwitch" @click="option = !option">
            <input class="switch" type="checkbox" :checked="option" />
        </button>
        <div v-if="!option" class="changeInput">
            <input type="text" v-model="msg" />
        </div>
        <div v-else class="changeInput">
            <input type="number" v-model="time" />
            <select v-model="typeTime">
                <option value="day">Dias</option>
                <option value="hour">Horas</option>
                <option value="min">Minutos</option>
            </select>
        </div>
        <div v-if="checkState('next')" class="next">{{ activeState }}</div>
        <div v-else class="next" @click="addNextState = true">
            <p v-if="!addNextState">Añadir siguiente estado</p>

            <select class="selectStates" v-model="activeState.next" v-if="addNextState">
                <option v-if="!state.used" v-for="state in states" :key="state.id" v-bind:value="state.id">{{ state.name }}</option>
            </select>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
    props: {
        gNew: { type: Boolean, default: false },
        gStates: Object,
        gState: Object,
        gPosition: Number
    },
    data() {
        return {
            // item: {
            //   position: -1,
            //   // hola: -1,
            //   new: false,
            //   next: false
            // },
            // resultTime: null,
            msg: '',
            option: false,
            //stateSelected: "",
            typeTime: 'min',
            edited: false,
            addNextState: false,
            time: 0,
            selected: false,
            tmpCheck: null,
            tmpName: null,
            filter_visible: true,
            filters: this.$store.getters['navigation/getCurrentPage'].filters ? JSON.parse(JSON.stringify(this.$store.getters['navigation/getCurrentPage'].filters)) : false,
            selects: this.$store.getters['navigation/getCurrentPage'].selects ? JSON.parse(JSON.stringify(this.$store.getters['navigation/getCurrentPage'].selects)) : false,
            actions: this.$store.getters['navigation/getCurrentPage'].actions ? JSON.parse(JSON.stringify(this.$store.getters['navigation/getCurrentPage'].actions)) : false,
            navInfo: this.$store.getters['navigation/getCurrentPage'] ? JSON.parse(JSON.stringify(this.$store.getters['navigation/getCurrentPage'])) : false
        }
    },
    computed: {
        states: {
            get() {
                for (const key in this.gStates) {
                    this.gStates[key].used = false
                }
                return this.gStates
            },
            set(val) {
                // console.log("seted value");
            }
        },
        // positionIn: {
        //   get() {
        //     return this.gPosition;
        //   },
        //   set(val) {
        //     console.log("seted value");
        //   }
        // },
        isNew() {
            return this.gNew
        },
        activeState: {
            get() {
                return this.gState
            },
            set(val) {
                // console.log("seted value");
            }
        },
        data() {
            return this.gData
        }
    },
    methods: {
        changeOption() {
            //   console.log(this.activeState.option);
            if (this.activeState.option) {
                this.activeState.option = false
            } else {
                this.activeState.option = true
            }
            // console.log(this.activeState.option)
        },
        calculateTime(value) {
            // console.log('calculateTime')
            // console.log(value)
            switch (value) {
                case 'min':
                    this.activeState.time = this.time
                    break
                case 'hour':
                    this.activeState.time = this.time * 60
                    break
                case 'day':
                    this.activeState.time = this.time * 60 * 24
                    break

                default:
                    break
            }
        },

        // addNextState() {
        //   // this.$emit("callNextState", this.item.stateSelected);
        // },
        checkState(val, state) {
            var result = false
            if (typeof this.activeState != 'undefined') {
                switch (val) {
                    case 'id':
                        if (typeof this.activeState.id != 'undefined') {
                            if (this.activeState.id != '' && this.activeState.id == state.id) {
                                result = true
                            }
                        }
                        break
                    case 'next':
                        if (this.activeState.next) {
                            result = true
                        }

                    default:
                        break
                }
            }
        },
        addeleteSelected() {
            // this.selected = !this.selected;
            // if (this.selected) {
            //   this.$emit("callSelected", this.activeState, 1);
            // } else {
            //   this.$emit("callSelected", this.activeState, 0);
            // }
        },
        getString(str) {
            return this.$t(str)
        },
        isValid(value) {
            var result = false

            if (typeof value == 'object') {
                if (typeof value != 'undefined' && value.length > 0) {
                    result = true
                }
            } else {
                if (typeof value != 'undefined' && String(value) != '') {
                    result = true
                }
            }

            return result
        },
        savetmp() {
            this.tmpCheck = this.value.status
            this.tmpName = this.value.name
        },
        addeleteChecked() {
            if (this.value.status == 0) {
                this.value.status = 1
            } else if (this.value.status == 1) {
                this.value.status = 0
            }
        },
        addEdited() {
            this.edited = true
        },
        cancelEdited(num) {
            this.edited = false
            if (num == 0) {
                this.value.status = this.tmpCheck
                this.value.name = this.tmpName
            }
            if (num == 1) {
                let self = this
                let store = self.navInfo.storeName
                let type = self.navInfo.storeType

                self.$store.dispatch(store + '/saveResult', { item: this.value, type: type }).then(function() {
                    let call = self.navInfo.loadList ? store + '/' + self.navInfo.loadList : store + '/loadList'

                    self.$store.dispatch(call, { type: type }).then(function(response) {})
                })
            }
        },
        upOrder() {
            this.$emit('callUp', this.value)
        },
        downOrder() {
            this.$emit('callDown', this.value)
        },
        cancelNew() {
            this.$emit('callCancel', true)
        },
        saveNew() {
            let self = this
            let store = self.navInfo.storeName
            let type = self.navInfo.storeType
            // console.log(this.value)
            self.$store.dispatch(store + '/saveNew', { item: this.value, type: type }).then(function() {
                let call = self.navInfo.loadList ? store + '/' + self.navInfo.loadList : store + '/loadList'

                self.$store.dispatch(call, { type: type }).then(function(response) {})
            })
            this.$emit('callCancel', true)
            this.value.name = ''
            this.value.status = 1
        }
    },
    mounted() {
        if (typeof this.activeState != 'undefined') {
            //this.stateSelected = this.activeState.id;
            if (this.activeState.time > 0 && this.activeState.msg == null) {
                this.time = this.activeState.time
                this.option = true
            } else {
                this.msg = this.activeState.msg
            }
        }
        if (typeof this.positionIn != 'undefined') {
            this.position = this.positionIn
        }
        if (typeof this.isNew != 'undefined') {
            this.new = this.isNew
        }
    },

    watch: {
        // activeState: {
        //   handler: function(val) {
        //     console.log("xdddd")
        //     console.log(val)
        //     // this.$emit("updateState", val);
        //   },
        //   deep: true
        // },
        typeTime: function() {
            this.calculateTime(this.typeTime)
            // this.$emit("callData", this.activeState);
        },
        time: function() {
            this.calculateTime(this.typeTime)
            // this.$emit("callData", this.activeState);
        },
        // msg: function() {
        //   this.$emit("callData", this.activeState);
        // },
        option: function() {
            this.activeState.option = this.option
        },
        msg: function() {
            this.activeState.msg = this.msg
        }
        // "activeState.id": function() {
        //   // this.$emit("callData", this.activeState);
        //   this.$emit("callUsed", this.activeState);
        //   // this.$emit("callUsed", this.item.stateSelected);
        // },
        // next: function() {
        //   this.$emit("callUsed", this.activeState);
        // }
    }
}
</script>

<style lang="scss">
.productstate {
    display: grid;
    grid-template-columns: 40px 160px 40px auto;
    grid-template-areas:
        'check selectStates inputSwitch changeInput'
        '. . . next ';
    .changeInput {
        grid-area: changeInput;
    }
    .next {
        grid-area: next;
    }
    .selectStates {
        grid-area: selectStates;
    }
    .inputSwitch {
        grid-area: inputSwitch;
    }
    .check {
        grid-area: check;
        place-self: center;
        border: 1px solid #777;
        border-radius: 2px;
        height: 15px;
        width: 15px;
        .tick {
            position: inherit;
            width: 20px;
            padding-bottom: 10px;
            @include background($image: img('tick_primary.svg'), $size: 15px, $position: left 6px center);
        }
    }

    // .contain {
    //   margin: 10px 0px;
    //   display: grid;
    //   grid-template-columns: 40px auto 40px;
    //   grid-template-areas:
    //     "check name arrows"
    //     ".  edit .";
    //   .check {
    //     grid-area: check;
    //     place-self: center;
    //     border: 1px solid #777;
    //     border-radius: 2px;
    //     height: 15px;
    //     width: 15px;
    //     .tick {
    //       position: inherit;
    //       width: 20px;
    //       padding-bottom: 10px;
    //       @include background(
    //         $image: img("tick_primary.svg"),
    //         $size: 15px,
    //         $position: left 6px center
    //       );
    //     }
    //   }
    //   .name {
    //     grid-area: name;
    //     align-self: center;
    //     height: 100%;
    //     background-color: #eee;
    //     display: grid;
    //     border-radius: 2px;
    //     grid-template-columns: 70% 30%;
    //     grid-template-areas: "value status";
    //     .value {
    //       grid-area: value;
    //       align-self: center;
    //       padding: 5px;
    //     }
    //     .status {
    //       grid-area: status;
    //       padding: 5px;
    //       align-self: center;
    //       justify-self: end;
    //       padding-left: 25px;
    //       .switch-input {
    //         position: relative;
    //         top: 2px;
    //         width: 25px;
    //         height: 15px;
    //         &:after {
    //           width: 12px;
    //           height: 12px;
    //         }
    //       }
    //     }
    //     .statusimg {
    //       @include background(
    //         $image: img("error_alert.svg"),
    //         $size: 15px,
    //         $position: left 6px center
    //       );
    //     }
    //     .checked {
    //       background-image: img("checked_primary.svg");
    //     }
    //     .default {
    //       padding: 5px;
    //       align-self: center;
    //       justify-self: initial;
    //       margin-left: 10px;
    //     }
    //   }
    //   .actions {
    //     grid-area: edit;
    //     text-align: right;
    //     padding-top: 5px;

    //     visibility: hidden;
    //     justify-self: end;
    //     .edit {
    //       text-align: right;
    //       @include background(
    //         $image: img("edit_primary.svg"),
    //         $size: 15px,
    //         $position: right 6px center
    //       );
    //       padding-right: 25px;
    //     }
    //     .save,
    //     .cancel {
    //       @include background($size: 15px, $position: left 6px center);
    //       display: inline-block;
    //       margin-left: 5px;
    //       padding-left: 25px;
    //     }
    //     .cancel {
    //       background-image: img("error_alert.svg");
    //     }
    //     .save {
    //       background-image: img("checked_ok.svg");
    //     }
    //   }
    //   .arrows {
    //     grid-area: arrows;
    //     display: grid;
    //     grid-template-columns: 100%;
    //     padding: 10px;
    //     margin: 0 auto;
    //     visibility: hidden;
    //     .top,
    //     .bottom {
    //       width: 15px;
    //       height: 15px;
    //       text-align: center;
    //       @include background($size: 15px, $position: center 6px center);
    //     }
    //     .top {
    //       background-image: img("up_dark.svg");
    //     }
    //     .bottom {
    //       background-image: img("down_dark.svg");
    //     }
    //   }
    //   .editMode {
    //     visibility: visible;
    //   }
    //   .editModeArrow {
    //     visibility: hidden;
    //   }
    //   .editModeArrow:hover {
    //     visibility: hidden;
    //   }
    // }
}
</style>
