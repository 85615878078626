<template>
    <div class="container">
        <div class="info-box">
            <div class="error-messages" v-if="errorMessages.length && options.editing">
                <v-chip v-for="err in errorMessages" flat class="chip--select-multi"> {{ err }} </v-chip>
            </div>

            <div class="column">
                <div class="field image">
                    <label>{{ $t('nvgt.ManageEmployee.label.avatar') }}</label>
                    <div v-if="!options.editing" class="value avatar" :style="{ backgroundImage: 'url(' + value.avatar + ')' }"></div>

                    <div v-if="options.editing" class="input avatar" :style="{ backgroundImage: 'url(' + newValue.avatar + ')' }">
                        <div class="btn addedit" @click="capture($event)">
                            <input type="file" name="change-image" class="input-file" accept="image/*" capture="camera" @change="imageReload" />
                        </div>
                        <button v-if="newValue.avatar" @click="imageDelete" class="btn del"></button>
                    </div>
                </div>

                <div class="field check status">
                    <button v-if="options.editing" class="input" @click="newValue.status = !newValue.status">
                        <input class="switch" type="checkbox" :checked="newValue.status" />
                        <span class="label">{{ $tc('nvgt.ManageEmployee.label.status', newValue.status) }}</span>
                    </button>

                    <span v-if="!options.editing" class="value" :data-value="value.status">{{ $tc('nvgt.ManageEmployee.label.status', value.status) }}</span>
                </div>
            </div>

            <div class="column">
                <div class="field text name">
                    <label>{{ $t('nvgt.ManageEmployee.label.name') }}</label>
                    <span v-if="!options.editing" class="value">{{ value.name }}</span>

                    <input v-if="options.editing" class="input" :class="{ required: !isValid(newValue.name) }" type="text" v-model.trim="newValue.name" />
                </div>

                <div v-if="options.editing" class="field number password">
                    <v-chip v-if="!options.adding" flat class="chip--select-multi" @click="changePassword = !changePassword">
                        {{ changePassword ? $t('nvgt.ManageEmployee.label.password_close') : $t('nvgt.ManageEmployee.label.password_change') }}
                    </v-chip>
                    <label v-if="changePassword || options.adding" class="value">{{ $t('nvgt.ManageEmployee.label.password_info') }}</label>
                    <input v-if="changePassword || options.adding" class="input" :class="{ required: !isValid(newPassword) }" type="number" min="0" v-model.trim.number="newPassword" />
                </div>
            </div>

            <div class="column">
                <div class="field select role">
                    <label>{{ $t('nvgt.ManageEmployee.label.role') }}</label>
                    <span v-if="!options.editing" class="value">{{ value.type_name ? value.type_name : $t('nvgt.ManageEmployee.label.role_empty') }}</span>

                    <v-select v-if="options.editing" v-model="newValue.type_name" class="input" :class="{ required: !isValid(newValue.type_name) }" :items="allRoles" solo flat hide-details>
                        <!-- @change="updateFilters()" -->

                        <template v-slot:item="data">
                            <v-list-tile-content>
                                <v-list-tile-title v-html="data.item"></v-list-tile-title>
                            </v-list-tile-content>
                        </template>
                    </v-select>
                </div>

                <div class="field select location">
                    <label>{{ $tc('nvgt.ManageEmployee.label.location', 1) }}</label>
                    <div v-if="!options.editing" class="value">
                        <v-chip v-for="loc in value.locations" class="chip--select-multi">
                            {{ loc ? loc.name : $t('nvgt.ManageEmployee.label.location_empty') }}
                        </v-chip>
                    </div>

                    <v-autocomplete v-model="newValue.locationsTrashify" v-if="options.editing" class="input" :class="{ required: !isValid(newValue.locationsTrashify) }" :items="allLocations" item-text="name" multiple flat solo hide-details hide-selected>
                        <template v-slot:selection="data">
                            <v-chip close class="chip--select-multi" @input="removeLocation(data.item)">
                                {{ data.item.name }}
                            </v-chip>
                        </template>

                        <template v-slot:item="data">
                            <v-list-tile-content>
                                <v-list-tile-title v-html="data.item.name"></v-list-tile-title>
                            </v-list-tile-content>
                        </template>
                    </v-autocomplete>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
    name: 'detail',
    props: {
        gValue: Object,
        gData: Object,
        gOptions: Object
    },
    data() {
        return {
            newValueData: {},
            changePassword: false,
            newPassword: '',
            errorMessages: []
        }
    },
    computed: {
        value: {
            get() {
                if (this.options.adding) {
                    return {
                        image: '',
                        avatar: '',
                        locations: [],
                        locationsTrashify: [],
                        name: '',
                        password: '',
                        status: 1,
                        type: 0,
                        type_name: ''
                    }
                } else {
                    // console.log(this.gValue);
                    return this.gValue
                }
            },
            set(val) {}
        },

        newValue: {
            get() {
                if (typeof this.newValueData == 'undefined' || Object.keys(this.newValueData).length <= 0) {
                    this.errorMessages = []
                    this.newValueData = Object.assign({}, this.value)
                }
                return this.newValueData
            },
            set(val) {
                this.newValueData = val
            }
        },

        data() {
            return this.gData
        },
        options: {
            get() {
                return this.gOptions
            },
            set(opts) {
                // console.log(opts);
            }
        },

        allLocations() {
            // console.log(this.$store.getters['location/getLocationsArray']);
            return this.$store.getters['location/getLocationsArray']
        },
        allRoles() {
            // console.log("this.$store.getters['employee/getNameRoles']");
            // console.log(this.$store.getters['employee/getNameRoles']);
            return this.$store.getters['employee/getNameRoles']
        }
    },
    methods: {
        saveChanges() {
            var self = this
            var errorMessages = []
            var updateLocations = []
            var roles = this.$store.getters['employee/getRoles']()

            this.newValue.file = this.newValue.avatar
            this.newValue.avatar = ''
            this.newValue.image = ''

            if (!this.isValid(this.newValue.name)) {
                errorMessages.push(this.$t('nvgt.ManageEmployee.label.name_error'))
            }

            if (this.allRoles && this.isValid(this.newValue.type)) {
                for (var index in roles) {
                    var rol = roles[index]
                    if (this.newValue.type_name == rol.name) {
                        this.newValue.type = rol.id
                    }
                }
            } else {
                errorMessages.push(this.$t('nvgt.ManageEmployee.label.role_error'))
            }

            if (this.changePassword || this.options.adding) {
                if (this.isValid(this.newPassword) && !isNaN(Number.parseInt(this.newPassword))) {
                    // this.newValue.password = sha256(Number.parseInt(this.newPassword));
                    this.newValue.password = sha256(String(this.newPassword))
                } else {
                    errorMessages.push(this.$t('nvgt.ManageEmployee.label.password_error'))
                }
                this.newPassword = ''
                this.changePassword = false
            }

            if (this.allLocations && this.isValid(this.newValue.locationsTrashify)) {
                if (this.newValue.locationsTrashify.length == Object.keys(this.newValue.locations).length) {
                    for (var index in this.newValue.locations) {
                        var location = this.newValue.locations[index]
                        if (this.newValue.locationsTrashify.indexOf(location.name) == -1) {
                            updateLocations.push(true)
                        }
                    }
                } else {
                    updateLocations.push(true)
                }

                if (true || updateLocations.indexOf(true) != -1) {
                    this.newValue.locations = []
                    for (var index in this.allLocations) {
                        var location = this.allLocations[index]
                        if (this.newValue.locationsTrashify.indexOf(location.name) != -1) {
                            this.newValue.locations.push(location.id)
                        }
                    }
                }
            } else {
                errorMessages.push(this.$t('nvgt.ManageEmployee.label.location_error'))
            }

            this.newValue.status = this.newValue.status ? 1 : 0

            // Valida imagen
            // if( typeof this.newValue.file != 'undefined' && this.newValue.file != "null"  && this.newValue.file != null ) {
            //   if( this.newValue.file.indexOf("data:") != -1 && this.newValue.file.indexOf(";base64,") != -1){ addImage = true; }
            // }

            // console.log("value 2 save");
            // console.log(this.newValue);

            if (errorMessages.length <= 0) {
                this.newValue.locations = JSON.stringify(this.newValue.locations)

                this.$store.dispatch('employee/' + (this.options.adding ? 'addEmployee' : 'editEmployee'), this.newValue).then(function () {
                    // console.log('STEP 9')
                    self.$store.dispatch('employee/loadList', {}).then(function () {})
                    // if( updateLocations.indexOf(true) != -1 ){
                    //   self.$store.dispatch( 'employee/updateLocation', self.newValue.id, self.newValue.locations )
                    // }
                })

                // console.log('guardamos')
                this.errorMessages = []
                this.options.adding = false
                this.options.doSave = false
                this.options.editing = false
                this.$emit('changeOpts', this.options)
            } else {
                // console.log('errores')
                this.errorMessages = errorMessages
                this.options.doSave = false
                this.$emit('changeOpts', this.options)
            }
        },

        imagePreview() {
            // console.log('imagePreview')
        },
        imageDelete() {
            Vue.set(this.newValue, 'avatar', '')
            Vue.set(this.newValue, 'image', '')
        },
        imageReload(e) {
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return

            var image = new Image()
            var reader = new FileReader()
            var self = this

            reader.onload = (e) => {
                Vue.set(self.newValue, 'avatar', e.target.result)
                // self.newValue.avatar = e.target.result;
            }
            reader.readAsDataURL(files[0])
        },
        capture(e, type) {
            if (process.env.VUE_APP_IS_APP === 'true') {
                e.preventDefault()
                e.stopPropagation()
                var self = this

                var cameraOptions = {
                    destinationType: 0,
                    quality: 70,
                    targetWidth: 1200,
                    targetHeight: 1200,
                    correctOrientation: true
                }

                this.$popup.confirmUpload({
                    title: this.$t('cordova.plugins.camera.title'),
                    textSaveStart: this.$t('cordova.plugins.camera.text_camera'),
                    textSave: this.$t('cordova.plugins.camera.text_library'),
                    callSaveStart: function () {
                        navigator.camera.getPicture(
                            function (imageData) {
                                imageData = 'data:image/jpeg;base64,' + imageData
                                var bgImg = new Image()
                                bgImg.onload = function () {
                                    // switch( type ){
                                    //   case 'answer': 	self.compValue = bgImg.src;
                                    //           break;
                                    //   case 'comment':	self.commentImage = bgImg.src;
                                    //           break;
                                    // }

                                    Vue.set(self.newValue, 'avatar', bgImg.src)
                                    // self.newValue.avatar = bgImg.src;
                                }
                                bgImg.src = imageData
                            },
                            function (message) {
                                // console.log(message)
                            },
                            cameraOptions
                        )
                    },
                    callSave: function () {
                        cameraOptions.sourceType = 0

                        navigator.camera.getPicture(
                            function (imageData) {
                                imageData = 'data:image/jpeg;base64,' + imageData
                                var bgImg = new Image()
                                bgImg.onload = function () {
                                    // switch( type ){
                                    //   case 'answer': 	self.compValue = bgImg.src;
                                    //           break;
                                    //   case 'comment':	self.commentImage = bgImg.src;
                                    //           break;
                                    // }
                                    Vue.set(self.newValue, 'avatar', bgImg.src)
                                    // self.newValue.avatar = bgImg.src;
                                }
                                bgImg.src = imageData
                            },
                            function (message) {
                                // console.log(message)
                            },
                            cameraOptions
                        )
                    }
                })
            } else {
                this.imageReload(e)
            }
        },

        removeLocation(toRemove) {
            const index = this.newValue.locationsTrashify.indexOf(toRemove.name)
            if (index >= 0) this.newValue.locationsTrashify.splice(index, 1)
        },

        isValid(value) {
            var result = false

            if (typeof value == 'object') {
                if (typeof value != 'undefined' && value.length > 0) {
                    result = true
                }
            } else {
                if (typeof value != 'undefined' && String(value) != '') {
                    result = true
                }
            }

            return result
        }
    },

    watch: {
        gOptions: {
            handler: function (value) {
                if (value.doSave) {
                    this.saveChanges()
                } else {
                    this.newPassword = ''
                    this.changePassword = false

                    this.newValue = Object.assign({}, this.value)

                    this.newValue.locationsTrashify = []
                    for (var index in this.newValue.locations) {
                        var loc = this.newValue.locations[index]
                        this.newValue.locationsTrashify.push(loc.name)
                    }
                }

                if (!value.editing) {
                    this.errorMessages = []
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
.container {
    $switch-width: 35px;
    $switch-height: 20px;
    $switch-color: $color-success-500;
    $avatar-width: 230px;

    background-color: #fff;
    width: 100%;
    height: calc(100% - 40px);
    margin: 20px auto;

    .info-box {
        padding: 24px 48px;
    }

    .column {
        @include display-inline-flex();
        @include justify-content();
        @include align-items(flex-start);
        @include flex-wrap(wrap);
        @include flex-direction(column);
        width: 33%;
        padding: 6px 12px;
    }

    .field {
        width: 100%;
        margin-bottom: 6px;

        .v-select__selection {
            margin: 0;
        }

        input:not(.switch),
        .v-input,
        .v-input__slot,
        .input,
        .value,
        .v-input__control .v-select__slot,
        .v-input__control {
            min-height: 30px;
        }
        input:not(.switch),
        .v-input,
        .v-input__slot,
        .input,
        .value {
            width: 100%;
            display: block;
            margin: 0;
            padding: 0.2rem 0.7rem 0.2rem 0.7rem;
        }

        .v-input,
        .input {
            padding: 0;
            // padding: 0.2rem 0;

            &.required {
                border-color: $color-primary-500;
                input {
                    border-color: $color-primary-500;
                }
            }
        }
        input:not(.switch),
        .v-input__slot,
        .value,
        .v-select__selection {
            padding: 0.2rem 0.7rem 0.2rem 0.7rem;
        }

        &.status {
            margin: 0;
            position: relative;
            max-width: $avatar-width;

            .value:before {
                @include border-radius(10px);
                content: '';
                display: inline-block;
                width: 8px;
                height: 8px;
                background-color: #bbb;
                position: absolute;
                top: 9px;
                left: 0px;
            }
            .value[data-value='1']:before,
            .value[data-value='true']:before {
                background-color: $color-success-500;
            }

            .input {
                .label {
                    display: inline-block;
                    padding: 3px 5px;
                    // line-height: 25px;
                    width: calc(100% - #{$switch-width});
                    float: right;
                }
            }
        }

        .avatar {
            @include border-radius(3px);
            @include background($color: #eee, $size: cover);
            width: $avatar-width;
            height: $avatar-width;

            .btn {
                @include border-radius(0 0 3px 3px);
                @include background($color: rgba($color-primary-500, 1), $size: 18px);
                width: 30px;
                height: 30px;
                transition-duration: 0.4s;
                margin: 0 6px;
                padding: 0;
                cursor: pointer;

                &.addedit {
                    background-image: img('edit_ffffff.svg');
                }
                &.del {
                    background-image: img('delete_ffffff.svg');
                }

                &:hover {
                    background-size: 19px;
                }

                .input-file {
                    @include opacity(0);
                    padding: 0;
                    margin: 0;
                    width: 100%;
                    height: 100%;
                    display: block;
                    cursor: pointer;
                }
            }
        }

        &.password {
            .v-chip {
                margin: 12px auto 3px auto;
            }
        }
    }

    .v-autocomplete {
        width: 240px;
        display: block;
        height: auto;

        .v-input__control {
        }
        .v-input__slot {
            margin: 0;
            background: none;
        }

        .v-select__selections {
            position: relative;
            height: auto;
            padding-bottom: 40px !important;

            > input {
                position: absolute !important;
                bottom: 0;
                width: 100%;
                min-width: 100px;
                display: block;
                left: 0;
            }
        }

        .v-text-field__details {
            display: none;
        }
    }

    .v-select {
        width: 200px;

        .v-input__slot {
            padding: 0 !important;
        }

        .v-select__selections {
            position: relative;

            .v-select__selection {
                @include text-ellipsis();
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
            input {
                width: 100%;
            }
        }
    }

    .switch {
        @extend .switch-input;

        &:checked {
            box-shadow: inset #{$switch-width - $switch-height} 0 0 0 $switch-color;
            border-color: $switch-color;
        }
    }

    .error-messages {
        width: 100%;

        .v-chip {
            background-color: $color-error-100;
            color: $color-error-500;
        }
    }
}
</style>
