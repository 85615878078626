<template>
    <div class="container">
        <div class="info-box">
            <div class="error-messages" v-if="errorMessages.length && options.editing">
                <v-chip v-for="err in errorMessages" :key="err" flat class="chip--select-multi">{{ err }}</v-chip>
            </div>

            <div class="column">
                <div class="field check status">
                    <div class="field text name">
                        <label class="labelText">{{ $t('nvgt.ManageProduct.label.name') }}</label>
                        <span v-if="!options.editing" class="value">{{ value.name }}</span>
                        <input v-if="options.editing" class="input" :class="{ required: !isValid(newValue.name) }" type="text" v-model.trim="newValue.name" />
                    </div>

                    <button v-if="options.editing" class="input" @click="newValue.status = !newValue.status">
                        <input class="switch" type="checkbox" :checked="newValue.status" />
                        <span class="label">{{ $tc('nvgt.ManageProduct.label.status', newValue.status) }}</span>
                    </button>

                    <span v-if="!options.editing" class="value" :data-value="value.status">{{ $tc('nvgt.ManageProduct.label.status', value.status) }}</span>
                    <label class="labelText">{{ $t('nvgt.ManageProduct.label.state') }}</label>
                </div>
            </div>

            <div class="column">
                <div class="field select role">
                    <label class="labelText">{{ $t('nvgt.ManageProduct.label.category') }}</label>

                    <span v-if="!options.editing" class="value">{{ value.category.id ? value.category.name : $t('nvgt.ManageProduct.label.category_empty') }}</span>
                    <v-select v-if="options.editing" v-model="value.category" class="input" :class="{ required: !isValid(value.category) }" :items="allCategories" solo flat hide-details return-object item-text="name" item-value="id">
                        <template v-slot:item="data">
                            <v-list-tile-content>
                                <v-list-tile-title v-html="data.item.name"></v-list-tile-title>
                            </v-list-tile-content>
                        </template>

                        <template v-slot:selection="data">
                            <div class="v-select__selection v-select__selection--comma">{{ data.item.name }}</div>
                        </template>
                    </v-select>
                </div>
            </div>
            <div v-if="!options.editing">
                <div v-for="state in value.states" :key="state.id">
                    {{ state.name }}
                    <div v-if="state.msg != '' && state.msg != null">Mensaje: {{ state.msg }}</div>
                    <div v-else>Tiempo: {{ formatTime(state.time) }}</div>
                    <div v-if="state.next != false">
                        <span v-if="state.next_name != ''">Siguiente: {{ state.next_name }}</span>
                        <span v-if="state.next_time != ''">Siguiente tiempo: {{ state.next_time }}</span>
                    </div>
                    <br />
                </div>
            </div>
            <div v-if="options.editing">
                <div class="editremove">
                    <button class="remove" v-on:click="remove()">{{ getString('nvgt.remove') }}</button>
                </div>
                <div class="add" v-on:click="addState">+ Añadir estado</div>

                <!-- <StateItem
          class="add"
          :gValue="newItem"
          :gNew="true"
          :gStates="allStates"
          :gPosition="Object.keys(value.states).length?Object.keys(value.states).length:0"
          @callSelected="addremoveSelected"
          @callData="stateData" @callUsed="usedState"
        ></StateItem> -->
                <StateItem :gStates="allStates" v-for="state in newValue.states" :key="state.id" v-if="state.status != -1" :gState="state" @callSelected="addremoveSelected" @callUsed="usedState"></StateItem>
                <!-- @callData="stateData"  -->
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import StateItem from '@/components/domain/manage/products/itemState'

export default {
    name: 'detail',
    components: { StateItem },
    props: {
        gValue: Object,
        gData: Object,
        gOptions: Object
    },
    data() {
        return {
            newValueData: {},
            newItem: {},
            changePassword: false,
            newPassword: '',
            selected: [],
            errorMessages: [],
            navInfo: this.$store.getters['navigation/getCurrentPage'] ? JSON.parse(JSON.stringify(this.$store.getters['navigation/getCurrentPage'])) : false
        }
    },
    computed: {
        allStates() {
            //   console.log(this.$store.getters["label/getStates"]);
            return this.$store.getters['label/getStates']
        },
        currentRoute() {
            return this.$route.name
        },
        value: {
            get() {
                if (this.options.adding) {
                    return {
                        name: '',
                        status: 1,
                        category: {
                            // id: "",
                            // name: ""
                        },
                        states: {}
                        // rel_id: "",
                        // name: "",
                        // time: 0,
                        // msg: null,
                        // next: false,
                        // next_name: "",
                        // next_time: ""
                    }
                } else {
                    return this.gValue
                }
            },
            set(val) {
                // console.log("seted value");
            }
        },

        newValue: {
            get() {
                // if (
                //   typeof this.newValueData == "undefined" ||
                //   Object.keys(this.newValueData).length <= 0
                // ) {
                this.errorMessages = []
                this.newValueData = this.value
                // this.newValueData = Object.assign({}, this.value);
                // }
                // console.log(this.value);
                // console.log(this.newValueData);
                // console.log("---------");
                return this.newValueData
            },
            set(val) {
                this.newValueData = val
            }
        },

        data() {
            return this.gData
        },
        options: {
            get() {
                return this.gOptions
            },
            set(opts) {
                // console.log(opts);
            }
        },

        allLocations() {
            return this.$store.getters['location/getLocationsArray']
        },
        allCategories() {
            var result = []
            for (const key in this.$store.getters['label/getCategories']) {
                result.push({
                    name: this.$store.getters['label/getCategories'][key].name,
                    id: key
                })
            }
            return result
        }
    },
    methods: {
        addState() {
            let newState = {
                // position: Object.keys(this.value.states).length,
                // stateSelected: "",
                // option: false,
                // msg: "",
                // resultTime: null,
                // new: false,
                // next: false
                id: '',
                rel_id: '',
                name: '',
                time: null,
                msg: null,
                next: false,
                status: 1,
                next_name: '',
                next_time: '',
                option: false
            }
            // this.value.states[Object.keys(this.value.states).length]= newState;

            Vue.set(this.newValue.states, Object.keys(this.newValue.states).length, newState)
        },
        usedState(item) {
            for (const key in this.newValue.states) {
                let state = this.newValue.states[key]
                // if(item.next != false){

                // }
                if (state.id == item.stateSelected) {
                    state.used = true
                }
                if (state.id != item.stateSelected && state.used) {
                    state.used = false
                }
            }
        },
        // addValues() {
        //   console.log("hi");
        //   this.newItem = {
        //     name: this.newValue.name,
        //     status: this.newValue.status,
        //     category: this.newValue.category,
        //     states: {
        //       // rel_id: "",
        //       // name: "",
        //       // time: 0,
        //       // msg: null,
        //       // next: false,
        //       // next_name: "",
        //       // next_time: ""
        //     }
        //   };
        //   return this.newItem;
        // },
        formatTime(time) {
            var result = time + ' Minutos'
            if (time >= 60) {
                var hour = 0
                var min = time
                do {
                    hour = min / 60
                    min = min % 60
                    min - 60
                } while (min >= 60)
                result = hour.toFixed(0) + ' Horas'
                if (min.toFixed(0) != 0) {
                    result += ' ' + min.toFixed(0) + ' Minutos'
                }
            }
            return result
        },
        getCategory(id) {
            // console.log(this.allCategories)
            this.allCategories.forEach((element) => {
                if (element.value == id) {
                    this.value.category.name = element.text
                }
            })
        },
        addremoveSelected(item, status) {
            if (status == 1) {
                this.$set(this.selected, item.id, item)
            } else {
                this.$delete(this.selected, item.id)
            }
        },
        // stateData(item) {
        //   console.log(item);

        //   if( item.id ){
        //     this.value.states[item.id] = item;
        //   } else {
        //     this.value.states[item.position] = item;
        //   }

        //   console.log(this.value);
        // },
        remove() {
            let self = this
            let store = self.navInfo.storeName

            switch (this.currentRoute) {
                case 'ManageProduct':
                    Object.values(this.selected).forEach((element) => {
                        self.$store.dispatch(store + '/removeReference', element).then(function (response) {
                            // self.$router.go();
                        })
                    })
                    break
                default:
                    break
            }
        },
        getString(str) {
            return this.$t(str)
        },
        saveChanges() {
            //   console.log("save changes");
            var self = this
            var errorMessages = []

            if (!this.isValid(this.newValue.name)) {
                errorMessages.push(this.$t('nvgt.ManageProduct.label.name_error'))
            }
            //   console.log(this.newValue.category.value);
            if (this.newValue.category.value && typeof this.newValue.category != 'string') {
                // console.log("OOF");
                this.newValue.category.id = this.newValue.category.value
                this.newValue.category.name = this.newValue.category.text
            }
            if (!this.isValid(this.newValue.category)) {
                errorMessages.push(this.$t('nvgt.ManageProduct.label.category_error'))
            }

            // console.log(this.newValue)
            if (!this.isValid(this.newValue.states)) {
                // console.log("error");
            } else {
                if (Object.keys(this.newValue.states).length > 0) {
                    for (const key in this.newValue.states) {
                        // console.log(this.newValue)
                        if (this.newValue.states[key].option) {
                            this.newValue.states[key].msg = ''
                        } else {
                            this.newValue.states[key].time = 0
                        }
                        // console.log(this.newValue)
                    }
                }
            }

            this.newValue.status = this.newValue.status ? 1 : 0

            //   console.log(this.newValue);
            if (errorMessages.length <= 0) {
                this.$store.dispatch('label/' + (this.options.adding ? 'addProduct' : 'editProduct'), this.newValue).then(function () {
                    self.$store.dispatch('label/loadList', {}).then(function () {})
                })

                // console.log("guardamos");
                this.errorMessages = []
                this.options.adding = false
                this.options.doSave = false
                this.options.editing = false
                this.$emit('changeOpts', this.options)
            } else {
                // console.log("errores");
                this.errorMessages = errorMessages
                this.options.doSave = false
                this.$emit('changeOpts', this.options)
            }
        },

        isValid(value) {
            var result = false
            //   console.log(value);
            if (typeof value == 'object') {
                if (typeof value != 'undefined') {
                    result = true
                }
            } else {
                if (typeof value != 'undefined' && String(value) != '') {
                    result = true
                }
            }

            return result
        }
        // created() {
        //   console.log("created");
        //   this.addValues();
        // }
    },
    mounted() {
        if (typeof this.value.category != 'undefined') {
            this.value.category.value = this.value.category.id
            this.value.category.text = this.value.category.name
        }
    },

    watch: {
        gOptions: {
            handler: function (value) {
                if (value.doSave) {
                    this.saveChanges()
                }

                if (!value.editing) {
                    this.errorMessages = []
                }
            },
            deep: true
        }
        // gNew: function() {
        //   console.log("gnew");
        //   this.addValues();
        // }
    }
}
</script>

<style lang="scss">
.container {
    $switch-width: 35px;
    $switch-height: 20px;
    $switch-color: $color-success-500;
    $avatar-width: 230px;

    background-color: #fff;
    width: 100%;
    height: calc(100% - 40px);
    margin: 20px auto;

    .info-box {
        padding: 24px 48px;
    }

    .column {
        @include display-inline-flex();
        @include justify-content();
        @include align-items(flex-start);
        @include flex-wrap(wrap);
        @include flex-direction(column);
        width: 50%;
        padding: 6px 12px;
    }

    .field {
        width: 100%;
        margin-bottom: 6px;

        .v-select__selection {
            margin: 0;
        }

        input:not(.switch),
        .v-input,
        .v-input__slot,
        .input,
        .value,
        .v-input__control .v-select__slot,
        .v-input__control {
            min-height: 30px;
        }
        input:not(.switch),
        .v-input,
        .v-input__slot,
        .input,
        .value {
            width: 100%;
            display: block;
            margin: 0;
            padding: 0.2rem 0.7rem 0.2rem 0.7rem;
        }

        .v-input,
        .input {
            padding: 0;
            // padding: 0.2rem 0;

            &.required {
                border-color: $color-primary-500;
                input {
                    border-color: $color-primary-500;
                }
            }
        }
        input:not(.switch),
        .v-input__slot,
        .value,
        .v-select__selection {
            padding: 0.2rem 0.7rem 0.2rem 0.7rem;
        }

        &.status {
            margin: 0;
            position: relative;
            width: 100%;

            .value:before {
                @include border-radius(10px);
                content: '';
                display: inline-block;
                width: 8px;
                height: 8px;
                background-color: #bbb;
                position: absolute;
                top: 15px;
                left: 0px;
            }
            .value[data-value='1']:before,
            .value[data-value='true']:before {
                background-color: $color-success-500;
            }
            .field {
                .labelText {
                    display: inline-block;
                    padding: 3px 9px;
                    // line-height: 25px;
                    width: calc(100% - #{$switch-width});
                }
            }
            .input {
                .labelText {
                    display: inline-block;
                    padding: 3px 9px;
                    // line-height: 25px;
                    width: calc(100% - #{$switch-width});
                    float: right;
                }
            }
        }

        // .avatar {
        //   @include border-radius(3px);
        //   @include background( $color: #eee, $size: cover );
        //   width: $avatar-width;
        //   height: $avatar-width;

        //   .btn {
        //     @include border-radius(0 0 3px 3px);
        //     @include background( $color: rgba($color-primary-500 1), $size: 18px );
        //     width: 30px;
        //     height: 30px;
        //     transition-duration: 0.4s;
        //     margin: 0 6px;
        //     padding: 0;
        //     cursor: pointer;

        //     &.addedit {
        //       background-image: img('edit_ffffff.svg');
        //     }
        //     &.del {
        //       background-image: img('delete_ffffff.svg');
        //     }

        //     &:hover{
        //       background-size: 19px;
        //     }

        //     .input-file {
        //       @include opacity(0);
        //       padding: 0;
        //       margin: 0;
        //       width: 100%;
        //       height: 100%;
        //       display: block;
        //       cursor: pointer;
        //     }

        //   }

        // }

        &.password {
            .v-chip {
                margin: 12px auto 3px auto;
            }
        }
    }

    .remove {
        background-color: #f5f5f5;
        padding: 5px;
        border-radius: 4px;
        margin-left: 10px;
        font-weight: bold;
    }

    .remove:hover {
        color: #8bc0ff;
    }
    .v-autocomplete {
        width: 240px;
        display: block;
        height: auto;

        .v-input__control {
        }
        .v-input__slot {
            margin: 0;
            background: none;
        }

        .v-select__selections {
            position: relative;
            height: auto;
            padding-bottom: 40px !important;

            > input {
                position: absolute !important;
                bottom: 0;
                width: 100%;
                min-width: 100px;
                display: block;
                left: 0;
            }
        }

        .v-text-field__details {
            display: none;
        }
    }

    .v-select {
        width: 200px;

        .v-input__slot {
            padding: 0 !important;
        }

        .v-select__selections {
            position: relative;

            .v-select__selection {
                @include text-ellipsis();
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
            input {
                width: 100%;
            }
        }
    }

    .switch {
        @extend .switch-input;

        &:checked {
            box-shadow: inset #{$switch-width - $switch-height} 0 0 0 $switch-color;
            border-color: $switch-color;
        }
    }

    .error-messages {
        width: 100%;

        .v-chip {
            background-color: $color-error-100;
            color: $color-error-500;
        }
    }
}
</style>
