<template>
    <section id="topbar" v-show="">
    <!-- <section id="topbar" v-show="this.$store.state.topbar_actions_visible"> -->

        <div class="title">{{page}}</div>

        <div id="breadcrumbs">
            <div v-for="(item, index) in breadcrumbs" class="item">
                <router-link class="name" :to="item.url">{{item.label}}</router-link>
            </div>

        </div>
        <div id="actions">
            <router-link class="button" :to="actions.main.url"><div class="item" id="main">{{actions.main.label}}</div></router-link>
            <div class="item more"></div>
        </div>

    </section>
</template>

<script>

export default {
  components: { },
  data () {
    return {
    }
  },
  computed: {

	breadcrumbs() {
		var sCurrentPage = this.$store.state.current_page;
		var ROUTER = this.$store.getters[ 'navigation/getRouting' ];
		var aBreadCrumbs = [];
		var bEnd = false;

		// while( !bEnd ){
		// 	aBreadCrumbs.push( ROUTER[ sCurrentPage ] );
		// 	if( ROUTER[ sCurrentPage ].parent_id == -1 ){
		// 		bEnd = true;
		// 	}

		// 	sCurrentPage = ROUTER[ sCurrentPage ].parent_id;
		// }

		return aBreadCrumbs;
		// return aBreadCrumbs.reverse();
	},

	page() {
		return this.$store.getters[ 'navigation/getCurrentPage' ].label;
    },

    actions() {
        return this.$store.getters[ 'navigation/getCurrentPage' ].actions;
    }
  },
  methods: {

  },
  created() {
  	var self = this;

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

	#topbar {
		height: 50px;
		width: 100%;
	    top: 60px;
	    position: fixed;
	    overflow: hidden;
	    padding: 0 10px;
	    border-bottom: 1px solid #ddd;

	    .title {
	    	float: left;
	    	font-size: 16px;
	    	font-weight: bold;
	    	text-transform: uppercase;
	    	margin-right: 10px;
	    	padding: 20px 10px;
	    	color: #0d122d;
	    }

		#breadcrumbs {
			display: inline-block;
			float: left;

			.item {
				float: left;
				margin: 0 10px;
				height: 50px;
    			padding: 20px 5px;
    			font-size: 14px;
    			cursor: pointer;

    			&:hover {
    				border-bottom: 3px solid #0d122d;
    			}

				.name {
					text-decoration: none;
					color: #333;
				}
			}

		}

		#actions {
			float: right;
			padding: 5px;

			.item {
				padding: 15px;
				display: inline-block;
				float: left;

				&#main {
					height: 35px;
					padding: 10px 20px;
					padding-left: 35px;
					text-align: center;
					margin-right: 15px;
					border-radius: 3px;
					font-size: 14px;
					text-transform: uppercase;
					color: #fff;
					cursor: pointer;
					background: url('/img/add.svg') no-repeat left 10px center #50ab2a;
					background-size: 15px;

					&:hover {
						background-color: #4CAF50;
					}
				}
				&.more {
					background: url('/img/menu_b.svg') no-repeat center;
					background-size: 25px;
					border: 1px solid #ccc;
					padding: 10px;
					height: 35px;
					width: 50px;
					background-color: #fff;
					cursor: pointer;
					border-radius: 3px;
				}
			}

		}
	}

	.separator {
		width: 100%;
		height: 60px;
	}
	.full_separator {
		width: 100%;
		height: 110px;
	}

</style>
